@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@500;600;700&display=swap');
@import url('https://pro.fontawesome.com/releases/v5.10.0/css/all.css');
@import "~bootstrap/scss/bootstrap.scss";

body {
	margin: 0;
	padding: 0;
	background: #f2f5ff;
	overflow-x: hidden;

	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
	padding: 0;
	margin: 0;
}

textarea {
	box-sizing: border-box;
}

div,
input,
section,
label {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: -moz-box;
	display: flex;
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	flex-direction: column;
	flex-shrink: 0;
	margin: 0;
	padding: 0;
	position: relative;
	-moz-box-align: stretch;
	align-items: stretch;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
select {
	-webkit-appearance: none;
	appearance: none;
}
select::-ms-expand {
	display: none;
}

.pagination {
	display: flex;
	list-style: none;
	align-items: center;
}

.pagination li {
	font-weight: bold;
	flex: 0 0 auto;
	margin: 0 8px;
	cursor: pointer;
	color: #b0bbd2;
}

.pagination li:hover {
	color: #68748d;
}

.pagination li a {
	outline: none;
}

.disabled {
	opacity: 0.3;
}

.previous,
.next {
	width: fit-content !important;
	margin: 0 !important;
}

.active-pagination {
	width: 26px;
	height: 26px;
	margin: 0 12px;
	background: #4f80ff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	color: #fff !important;
	border-radius: 99999px;
}

.break-me a {
	color: #e2e9f9;
}

.Toastify {
	z-index: 999999;
}
.Toastify__toast {
	font-family: 'Sarabun', sans-serif;
	padding: calc(1.5rem - 2.5px) 1.5rem 1.5rem 1.5rem;
	border-radius: 0.1rem;

	.Toastify__toast-body {
		justify-content: center;
		font-size: 1rem;
	}
	.Toastify__close-button {
		display: none;
	}
}

@media screen and (max-width: 576px) {
	.Toastify__toast-container {
		padding: 0.5rem;

		.Toastify__toast {
			margin-bottom: 0.5rem;
		}
	}
}

@media only screen and (max-width: 762px) {
	body {
		overflow-y: hidden;
	}

	.pagination {
		margin: 4px 0;
		padding: 0;
	}
}

.modal-header, .modal-footer {
	flex-direction: row;
}

@media (min-width: 1400px) {
	.modal-dialog {
		max-width: 1200px !important;
	}
}

.ant-switch {
	background-color: #ff4d4f;
}

.ant-switch-checked {
	background-color: #41bb0a;
}

.btn-purple {
	&.ant-btn-primary {
		background-color: #531dab;
		border-color: #531dab;
	}
}